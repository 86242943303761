import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AuthorBlock title="Alex Munteanu" subtitle="PSI Board Chairperson" mdxType="AuthorBlock">
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "730px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/656bd434711c4ab133ea1f7955b8e1d6/1a144/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAAFAv/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAEGhn6EORdTKWhqo8eT/8QAHhAAAgICAgMAAAAAAAAAAAAAAgMAAQQREhMhIjP/2gAIAQEAAQUCUHax6BpcTfEy+fiAO35BcMfsuoF+2YW3z//EABcRAAMBAAAAAAAAAAAAAAAAAAEQIUH/2gAIAQMBAT8BAixf/8QAFREBAQAAAAAAAAAAAAAAAAAAEAH/2gAIAQIBAT8BIf/EAB4QAAEDBAMAAAAAAAAAAAAAAAEAAhEDECFBMVJy/9oACAEBAAY/AgOES1xxq0FOgLK8pjNuybVFHURb/8QAHhAAAgICAgMAAAAAAAAAAAAAAAERITFBUYGRoeH/2gAIAQEAAT8hZKRy2NLITLZjRwA6sk42lNCWixSFhWfRcnCKBIRJaLbj38GM1QngeT//2gAMAwEAAgADAAAAELzbwP/EABcRAQEBAQAAAAAAAAAAAAAAAAEQQVH/2gAIAQMBAT8QFTHXZ//EABoRAAICAwAAAAAAAAAAAAAAAAABECERQVH/2gAIAQIBAT8QbvEaFcP/xAAgEAEBAAIBAwUAAAAAAAAAAAABEQAhMVGxwUFhgZGh/9oACAEBAAE/EGuQNG6O7i/SgSdUnrgpkPvc0qRQqFeMa4VUvRWYk4BreQDuO7ga/vfDmHdApz3T6wZBnUuM6J+KKfDPEYSvOJcf/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/656bd434711c4ab133ea1f7955b8e1d6/1a144/01.jpg",
              "srcSet": ["/static/656bd434711c4ab133ea1f7955b8e1d6/f93b5/01.jpg 300w", "/static/656bd434711c4ab133ea1f7955b8e1d6/b4294/01.jpg 600w", "/static/656bd434711c4ab133ea1f7955b8e1d6/1a144/01.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`The 2020 / 21 school year has been severely marked by the COVID-19 pandemic, and full, partial and regional lockdowns introduced by authorities to counteract its proliferation.`}</p>
    </blockquote>
    <p>{`PSI had to adjust to the new realities by changing its modus operandi to ensure a strong academic process while preserving security, safety and well-being of our community. It was an unprecedently challenging year for all and still - a great success – as our community has shown its strength, unity and resilience during trying times! And an excellent example for our student body to follow – an unexpected but very useful life lesson…`}</p>
    <p>{`As never before, I’d like to express my greatest gratitude and appreciation to our Director Rachel Caldwell for her leadership and crisis management, to the Leadership Team, all faculty and staff, my fellow Board members, and the PTA for their commitment, dedication and flexibility. And, again, my special thanks go to our greatest and bravest community members, our students and parents, who adjusted to new realities with courage, self-dedication, grace and humor.`}</p>
    <p>{`To face imminent challenges and threats, PSI Board of Governors (BOG) took a proactive approach and reunited on a monthly basis during the summer of 2020. A specially dedicated Re-Entry task force - consisting of Board of Governor members and Leadership Team - was established and reunites every week to make quick and expert decisions – which remains effective through spring 2021. We finally see the light at the end of the tunnel with the vaccination process in progress, and we’re very hopeful to open our campus for the 2021 / 22 school year.`}</p>
    <p>{`Despite all unprecedented challenges, the main goal of the PSI community remains to stay focused on educational activities. PSI’s IT platform keeps improving, on-line learning and counselling have been developed quickly to ensure distant learning under a blended model.`}</p>
    <p>{`I’d like to assure the PSI community that our financial position remains very strong thanks to many years of good management, sound and responsible corporate governance that provide a solid base for unprecedented and unexpected expenses – as we experienced during the pandemic – and, most importantly, for Investment. We have made significant progress on ‘Phase III” of our facilities’ real estate development and plan to launch this project in spring 2022.`}</p>
    <p>{`During the 2020 / 21 school year, the BOG has finalised Leading School Benchmarks (LSBs) and plans to review PSI’s strategy based on those standards next year. A new project focused on Community, Diversity, Inclusion has been launched and will continue next year. We have also made significant progress with our legal restructuring project.`}</p>
    <p>{`We’ll always keep learning, improving, excelling and leading.`}</p>
    <p><strong parentName="p">{`Alex Munteanu`}</strong><br parentName="p"></br>{`
`}<em parentName="p">{`Board Chairperson`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      